import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Image, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsCheckCircle } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Noleggio di yacht Kateryna
			</title>
			<meta name={"description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:title"} content={"Noleggio di yacht Kateryna"} />
			<meta property={"og:description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="text" />
		</Components.Header>
		<Components.Hero>
			<Override slot="image" src="https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000" border-radius="30px" box-shadow="--xl" />
			<Override slot="text">
				Benvenuti a Noleggio di yacht Kateryna
			</Override>
			<Override slot="text1">
				Scopri la gioia del mare con Noleggio di yacht Kateryna. La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. Benvenuto a bordo, dove la tua prossima grande avventura avrà come sfondo un blu infinito.
			</Override>
			<Override slot="link">
				Servizi
			</Override>
		</Components.Hero>
		<Section padding="80px 0 90px 0" quarkly-title="Product-1">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 20px/1.5 --fontFamily-sans"
				color="#38aebd"
				text-transform="uppercase"
			>
				IL TUO VIAGGIO INIZIA QUI
			</Text>
			<Text
				margin="0px 0px 1rem 0px"
				font="normal 600 56px/1.2 --fontFamily-serifGaramond"
				width="50%"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				md-margin="0px 0px 40px 0px"
			>
				Chi siamo
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				align-self="center"
				margin="0px 0px 70px 0px"
				sm-display="grid"
				sm-align-self="auto"
				sm-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-rows="repeat(2, 1fr)"
				sm-grid-gap="25px 30px"
				md-align-self="auto"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					margin="0px 50px 0px 0px"
					sm-margin="0px 0 0px 0px"
					md-margin="0px 35px 0px 0px"
				>
					<Icon category="bs" icon={BsCheckCircle} size="64px" margin="0px 0px 10px 0px" />
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" text-transform="uppercase">
						VIAGGI SENZA GIUNTE
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					margin="0px 50px 0px 0px"
					sm-margin="0px 0 0px 0px"
					md-margin="0px 35px 0px 0px"
				>
					<Icon category="bs" icon={BsCheckCircle} size="64px" margin="0px 0px 10px 0px" />
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" text-transform="uppercase">
						VIAGGI ECOLOGICI
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					margin="0px 50px 0px 0px"
					sm-margin="0px 0 0px 0px"
					md-margin="0px 35px 0px 0px"
				>
					<Icon category="bs" icon={BsCheckCircle} size="64px" margin="0px 0px 10px 0px" />
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" text-transform="uppercase">
						ESPERIENZE MARITTIME ARTIGIANALI
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Icon category="bs" icon={BsCheckCircle} size="64px" margin="0px 0px 10px 0px" />
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" text-transform="uppercase">
						PRIVILEGI ESCLUSIVI
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12:34:49.903Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-1.jpg?v=2024-04-08T12%3A34%3A49.903Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Navigazione esperta
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						I nostri capitani sono marinai esperti, garantendo che il tuo viaggio sia sicuro e splendido.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12:34:49.900Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-4.jpg?v=2024-04-08T12%3A34%3A49.900Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Itinerari personalizzati
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Ogni viaggio è una narrazione creata ad arte, che si svolge al tuo comando.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1593351415075-3bac9f45c877?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Comfort supremo
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Le nostre navi sono paradisi di lusso, dotati di comfort che promettono serenità e gioia.
					</Text>
				</Box>
			</Box>
			<Button
				font="normal 500 18px/1.5 --fontFamily-sans"
				href="/services"
				padding="0 0 0 0"
				background="0"
				color="--secondary"
				text-transform="uppercase"
				type="link"
				margin="2rem 0px 0px 0px"
			>
				CREA IL TUO RACCONTO OCEANICO -&gt;&gt;
			</Button>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					La nostra storia di viaggio
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					Noi di Noleggio di yacht Kateryna crediamo che il mare sia una tela e che ogni viaggio che facciamo dipinga una nuova immagine. Nata dalla passione per il blu profondo e la libertà delle onde, la nostra storia è una storia di connessione tra terra, acqua e gli avventurieri che cercano di attraversarle entrambe.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1621277224630-81d9af65e40c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Esperienze marinaresche artigianali
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Ogni yacht della nostra flotta è più di una nave, è un'esperienza attentamente curata in attesa di svolgersi. Appena sali a bordo, entri in un mondo in cui ogni dettaglio è sintonizzato al ritmo del mare. Dai ponti lucidi alle vele croccanti, ogni elemento è scelto per migliorare il tuo viaggio.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/services"
							padding="0 0 0 0"
							background="0"
							color="--secondary"
							text-transform="uppercase"
							type="link"
						>
							CREA IL TUO RACCONTO OCEANICO -&gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="text6" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6606aeb30072400020ebce37"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});